@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap");
body {
  font-family: "Nunito", sans-serif;
  margin: 0;
  /* background-color: rgb(114, 89, 70); */
  background-image: linear-gradient(
      rgba(50, 50, 50, 0.8),
      rgba(50, 50, 50, 0.8)
    ),
    url("../assets/beans.jpg");
  background-position: left center;
  background-attachment: fixed;
  background-size: cover;
}

h1 {
  font-weight: 200;
  font-size: 4rem;
}

h3 {
  color: rgb(240, 228, 202);
}

p {
  font-weight: 200;
}

.text-light {
  color: rgb(240, 228, 202);
}

ul {
  list-style: none;
}

iframe {
  width: 100%;
}

a {
  color: black;
}

a:hover {
  text-decoration: none;
}

object {
  width: 100%;
}

.admin-form {
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: whitesmoke;
  width: 100%;
  height: 60px;
}

.admin-form button {
  margin-bottom: 7px;
}

.article {
  box-shadow: 8px 8px 9px black;
}

.blend-card {
  padding: 5%;
  background-color: rgb(222, 210, 201);
  margin: 2% auto;
  width: 100%;
}

.blog-post img {
  max-width: 100%;
}

.post-card {
  max-height: 300px;
  margin-top: 40px;
  overflow: hidden;
}

.box {
  width: 100%;
  margin-bottom: 1%;
  border-radius: 5px;
  background-color: whitesmoke;
  padding: 2%;
}

.read-more {
  color: rgb(222, 210, 201);
  margin-bottom: 30px;
}

.blog-post h3 {
  color: #212529 !important;
}

.cart-btn {
  color: rgb(156, 153, 153);
}

.comments-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.comments-form input,
textarea {
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 5px;
  border: none;
}

.comment-submit-button {
  margin-top: 10px;
  border: none;
}

.comment-box {
  border-top: 1px solid #bbbbbb;
  padding: 0 10px;
  padding-top: 20px;

  background-color: #eeeeee;
}

.btn-light {
  background-color: steelblue;
  color: whitesmoke;
}

.cart-btn:hover {
  cursor: pointer;
  color: rgb(186, 177, 169);
}

.cart-btn-wrapper {
  display: flex;
  flex-direction: row;
}

.coffee-card {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5%;
  margin: 5px;
  min-height: 350px;
  box-shadow: 2px 2px 5px black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.coffee-card h6 {
  color: black;
  text-align: center;
}

.coffee-card img {
  border-radius: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.coffee-corner-article {
  overflow: scroll;
  height: 100vh;
}

.coffee-corner-header {
  display: flex;
  justify-content: space-between;
}

.coffee-corner-list {
  height: 100vh;
  overflow: scroll;
}

.coffee-button {
  align-self: center;
}
.contact {
  text-align: center;
  padding: 4%;
}

.contact-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(223, 217, 213);
  padding: 1%;
  margin: 1%;
  height: 120px;
}

.btn:disabled {
  cursor: not-allowed;
}

.empty-cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.home-coffeeclub-banner {
  background-color: rgba(50, 49, 48, 0.8);
  color: rgb(214, 204, 195);
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 80%;
  margin: 0 auto 20px;
}

.home-coffeeclub-banner p {
  margin: 9px 0px;
}

.home-coffeeclub-banner a {
  color: rgb(222, 210, 201);
}

.home-coffeeclub-banner a:hover {
  color: steelblue;
}

.film {
  margin: 0;
  padding: 1px;
}

.contents {
  margin-top: 10px;
  padding: 4%;
  min-height: 100vh;
  text-align: justify;
}

.btn {
  margin-right: 3%;
  margin-bottom: 4%;
}

.btn-primary {
  color: whitesmoke;
}

.btn-dark {
  border: none;
  color: whitesmoke;
}
.btn-dark:hover {
  background-color: steelblue;
  border: none;
}

.cart-logo {
  width: 80%;
  margin: 10px auto;
}

.cart-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown {
  width: 100%;
}

.option-dropdown {
  background-color: rgb(186, 177, 169);
  outline: none;
  width: 100%;
  margin-bottom: 10px;
}
.event {
  background-color: #eee;
  padding: 4%;
  margin-bottom: 2%;
}

.light-text-bullets {
  color: rgb(214, 204, 195);
  font-style: italic;
  font-size: larger;
}

.light-text-bullets a {
  color: white;
  text-decoration: underline;
}

.text-box {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 4%;
  margin-bottom: 2%;
}

.hero {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7%;
  color: whitesmoke;
  background-image: url("../assets/imgs/herotest.jpg");
  background-position: left center;
  background-attachment: fixed;
  background-size: cover;
}

.choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10%;
  margin: 2%;
  color: rgb(5, 76, 94);
  width: 100%;
  height: 300px;
}

.footer-buttons {
  display: flex;
  justify-content: space-evenly;
}

.list:hover {
  cursor: pointer;
}

.meta {
  display: flex;
  align-items: center;
}

.meta a {
  margin-left: 5px;
}

.icon {
  color: rgb(5, 76, 94);
  margin-bottom: 3%;
}

.biog {
  font-weight: 100;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
}

.product-card {
  /* display: inline-block; */
  margin: 4px;
  width: 300px;
  flex: 0 0 auto;
  scroll-snap-align: center;
}

.product-list {
  background-color: rgba(50, 49, 48, 0.8);
  height: 485px;
  /* overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap; */
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.slider-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: whitesmoke;
}

.store {
  display: flex;
  flex-wrap: wrap;
}

.store-buttons {
  display: inline-block;
  width: 500px;
  color: rgb(222, 210, 201);
}

.titlebar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 25px;
  color: rgb(240, 228, 202);
}

.button {
  padding: 2%;
  margin: 2%;
  background-color: rgb(74, 35, 5);
  color: whitesmoke;
  text-align: center;
  border-radius: 5px;
}

.button:hover {
  border: 1px solid rgb(74, 35, 5);
  background-color: whitesmoke;
  color: rgb(74, 35, 5);
  transition: 500ms;
}

.location-card {
  display: flex;
  flex-direction: column;
  padding: 5%;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: whitesmoke;
}

.cart {
  margin: 0;
  padding: 5px;
}

.cart-item {
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(233, 227, 223);
}

.cart-img {
  border-radius: 5px;
}

.cart-remove {
  color: red;
}

.cart-remove:hover {
  cursor: pointer;
}

.sorting-buttons {
  display: inline-block;
  width: 50%;
}

.sharing-buttons {
  text-align: right;
}

.toolbar-menu {
  width: 150px;
  margin: auto;
}
.item {
  background-color: whitesmoke;
  padding: 4%;
  margin: 1%;
  box-shadow: 5px 5px lightgray;
  border-radius: 10px;
}

.item img {
  width: 65%;
}

.photo {
  width: 85%;
}

.photo-container {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.quantity-button {
  border: none;
  border-radius: 50px;
  padding: 0 8px;
  margin: 5px;
}

.quantity-button:hover {
  background-color: rgb(94, 87, 72);
  color: whitesmoke;
}

.quantity-button:focus {
  outline: none;
}
.video {
  width: 100%;
}

/*image overlays*/

.main-box {
  width: 100%;
  position: relative;
  float: left;
  margin: 0 4px;
}
.main-box img {
  position: relative;
  z-index: 1;
}

.overlay img {
  width: 70%;
  margin: auto;
}
.overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  opacity: 0;
  color: #fff;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  background-color: #05050598;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
  text-align: center;
}
.main-box:hover .overlay {
  opacity: 0.9;
}

.review {
  margin: 1%;
  padding: 2%;
  text-align: center;
  background-color: rgb(160, 137, 119);
  border-radius: 5px;
}

.social {
  margin-left: 1%;
}

.social-links {
}

.social-links a {
  color: whitesmoke;
}

.title-box {
  background-color: rgba(50, 50, 50, 0.8);
  padding: 2%;
}

.portrait {
  width: 100%;
}

.logo {
  width: 35%;
  margin-bottom: 5%;
}

/* LIGHTBOX ETC */
/* image grid styles */
.img-grid {
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}
.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img {
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  position: absolute;
  top: 0;
  right: 0;
}

/* modal styles */
.backdrop {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 40px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

.backdrop h5 {
  text-align: center;
  color: white;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
